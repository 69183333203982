import React from "react"
import { Link } from "gatsby"
import myPicture from "../images/me150copy.png"
import Div100vh from "react-div-100vh"

export default function about() {
  return (
    <Div100vh id="entire">
      <header>
        <nav>
          <ul id="headerMenu">
            <li className="menuItem" id="name">
              <Link to="/">hannah s. kim</Link>
            </li>
            <li className="menuItem" id="about">
              <Link to="/about">about</Link>
            </li>
          </ul>
        </nav>
      </header>
      <div className="hero">
        <div className="aboutPageContent">
          <img id="aboutPicture" src={myPicture} alt="me" />
          <div id="secondThing">
            <p>
              Hi, I'm Hannah! I'm a UX researcher with a psychology and
              human-computer interaction background.
            </p>
          </div>
          <div id="thirdThing">
            <p>
              I currently feel most comfortable wearing my researcher hat.
              However, I have growing interests in design and front-end code (I
              built this website from scratch!) and enjoy logging learning time
              towards both during some of my non-Workday (where I've been since
              September 2019) hours.
            </p>
            <p>
              I'm curious, love to learn, and I've been told I ask good
              questions—perhaps unsurprising since I'm a researcher—but it's a
              skill I'm continuously looking to improve.
            </p>
          </div>
          <div id="fourthThing">
            <h1 className="heroSectionProjectTitles">Previously</h1>
            <ul>
              <li className="sectionListItem">
                [May '19]{" "}
                <span role="img" aria-label="graduation cap">
                  🎓
                </span>{" "}
                from Carnegie Mellon University with a B.S. in psychology and
                human-computer interaction
              </li>
              <li className="sectionListItem">
                [Oct '18 - May '19] UX research intern at Expii
              </li>
              <li className="sectionListItem">
                [May - Aug '18] UX research intern at Workday
              </li>
              <li className="sectionListItem" id="aboutSectionListItem">
                [May - Aug '17] Research assistant at Carnegie Mellon's
                Human-Computer Interaction Institute
              </li>
            </ul>
          </div>
        </div>
      </div>
      <footer>
        <ul id="footerLinks">
          <li>
            <a
              href="mailto: hello@hannahs.kim"
              className="footerItem"
              id="email"
            >
              hello@hannahs.kim→
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/hannahs-kim"
              className="footerItem"
              id="linkedIn"
            >
              My LinkedIn→
            </a>
          </li>
        </ul>
      </footer>
    </Div100vh>
  )
}
